// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';
// STYLING
import EmailFormWrapper, { Styles } from './emailForm.styles'
// LOGIC AND HANDLERS
// import { InputHandler } from './handlers'; // Code to handle user I/O
// NPM MODULES
import { Formik }  from 'formik'

// Formik email form Component
const EmailForm = ({ heading, subheading, formikOnSubmit }) => {
  const { row, buttonStyle } = Styles 
  return (
  <EmailFormWrapper>
    <Container>
      <Box {...row}>

        { (heading || subheading) ?
        <Box {...row}>
          <h2>{heading}</h2>
          <p>{subheading}</p>
        </Box>:<></>
        }

        <Formik
        initalValues={{ email: '' }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          formikOnSubmit( values, actions )
          // setTimeout(() => {
            
            // console.log(values);
            // const customDict = '';
            // const speakerObj = InputHandler.updateSpeakerObj(values.speakers);
            // const jobObj = { episode, speakerObj, customDict };
            // InputHandler.addJob(jobObj, 'users/job');
            // alert('Sending login link to:' + JSON.stringify(values, null, 2));
            // // resetForm()
            actions.setSubmitting(false);
          // }, 1000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <Box {...row}>
          <form id='emailForm' onSubmit={handleSubmit}>
              <Input
                inputType="email"
                label="email"
                isMaterial={true}
                isFormik={true}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ backgroundColor: 'rgb(255,255,255,0)' }}
              />
            <Box {...row} >
              <Button
                title="Continue"
                style={{ width: '100%' }}
                type="submit"
                form="emailForm"
                onClick={handleSubmit}
                disabled={isSubmitting}
                {...buttonStyle}
              />
            </Box>
          </form>
        </Box>
        )}
        </Formik>
      </Box>
    </Container>
  </EmailFormWrapper>
  )
}

export default EmailForm