// REACT AND GATSBY
import React, { useState } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
// import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';
import PodcastSearch from 'common/src/components/PodcastSearch';
import PodcastTile from 'common/src/components/InfoTile/podcastTile';
import EmailForm from './emailForm';
// STYLING
import SignUpWrapper, { Styles } from './signup.styles';
// LOGIC AND HANDLERS
import { emailSignup } from './handlers';
// NPM MODULES

// Sign Up Component
const SignUp = ({ heading, type = 'organic', onSignInClick }) => {
  let [podcast, updatePodcast] = useState({});

  const { row } = Styles;

  return (
    <SignUpWrapper>
      <Container>
        <Box {...row}>
          <Box {...row}>
            <h2>{heading}</h2>
            {/* <p>Signup</p> */}
          </Box>

          <Box {...row}>
            <PodcastSearch
              tileClickCB={(podcast) => {
                document.getElementById('subscribe').scrollIntoView();
                updatePodcast(podcast);
              }}
            />
          </Box>

          {/*** SELECTED PODCAST ***/}
          <Box id="subscribe" {...row}>
            {podcast.image ? (
              <Box {...row}>
                <h4>{podcast.title_original}</h4>
                <PodcastTile tileStyle="imageOnly" podcast={podcast} />
                <p>{podcast.total_episodes + ' episodes'}</p>
                <EmailForm
                  heading="Enter your email address"
                  formikOnSubmit={(values, actions) => {
                    console.log(values);
                    emailSignup({ podcast, email: values.email, type: 'beta' });
                    // alert(' submitting: ' + JSON.stringify(values, null, 2));
                    // emailLogin(values.email)
                    // betaSignup()
                  }}
                />
              </Box>
            ) : (
              <Box {...row}>
                <p>Already have an account?</p>
                <Button
                  title="SIGN IN"
                  style={{ width: '100%' }}
                  onClick={() => onSignInClick()}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </SignUpWrapper>
  );
};

export default SignUp;
