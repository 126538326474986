import styled from 'styled-components';

export const EmailFormWrapper = styled.div`
`
const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    mt: '20px',
    mb: '40px',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    color: '#03103b',
    borderRadius: '30px'
  },
};

export { Styles };
export default EmailFormWrapper;
