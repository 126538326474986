/**
 * Handler for input events for Newsletter
 *
 * This is where we call our custom API to manage Job requests
 */
import { doFetch as podstacksAPI } from '../../../../../api/PodstacksAPI';
// import { handleEnterKey } from '../../../../../api/BrowserAPI';
import fetch from 'cross-fetch';
// const nanoid = require('nanoid');
import nanoid from 'nanoid';

// Returns if user already exists
const userExists = async (userId, email) => {
  console.log('Checking if user exists: ' + userId);
  const userFromId = await podstacksAPI('users', 'getUser', { userId });
  const userFromEmail = await podstacksAPI('users', 'filterUsers', null, null, {
    filter: { identity: email },
  });
  // console.log({userFromId, userFromEmail})
  return {
    userIdExists: userFromId ? true : false,
    emailExists: userFromEmail.length > 0 ? true : false,
  };
};

// Handles user login with email
export const emailLogin = async (email) => {
  console.log('Requesting magic login link: ' + email);
  const res = await podstacksAPI('auth', 'getMagicLink', null, { email });
  if (!res) {
    console.log('Request for magic link failed!');
    alert(
      'Invalid email: ' +
        email +
        '\nPlease check your email and try again.\nOr, sign up if you dont have an account.'
    );
  } else {
    alert(
      'Email sent to: ' + email + '\nPlease check your email for login link.'
    );
  }
};

// Stores email in the database using an API
export const emailSignup = async ({ podcast, email, type }) => {
  console.log('Creating ' + type + ' signup: ' + email);
  // console.log({podcast, email, type})
  // const res = true
  const tracking = await fetch('https://ipapi.co/json/'); // SHHH Don't tell anyone!
  // Verify user does not exist before modifying database
  const { userIdExists, emailExists } = await userExists(podcast.id, email);
  console.log({ userIdExists, emailExists });
  let res = null;
  if (!userIdExists && !emailExists) {
    res = await podstacksAPI('users', 'createUser', null, null, {
      user: {
        userId: podcast.id ? podcast.id : nanoid(8),
        customerId: ' ',
        type,
        billing: {},
        identity: email,
        jobs: [],
        login: {},
        notify: {},
        podcast: podcast ? podcast : {},
        status: {},
        tracking: await tracking.json(),
        urls: {},
      },
    });

    // Send magic link upon signup.
    await podstacksAPI('auth', 'getMagicLink', null, { email });
    if (res) {
      alert(
        'Thanks ' + email + '!\nWe sent a link to login to this email address.'
      );
      window.location.reload(true);
    } else {
      alert('ERROR!');
    }
  } else {
    alert('Sorry, this user/email already exists!');
  }
};
