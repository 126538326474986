// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import EmailForm from './emailForm';
// STYLING
import SignInWrapper, { Styles } from './signin.styles';
// LOGIC AND HANDLERS
// import { InputHandler } from './handlers'; // Code to handle user I/O
import { emailLogin } from './handlers';
// NPM MODULES

// Sign-in/Log-in Component
const SignIn = ({ heading, onSignUpClick }) => {
  const { row } = Styles;
  return (
    <SignInWrapper>
      <Container>
        <Box {...row}>
          <EmailForm
            heading={heading}
            subheading="Enter your email address"
            formikOnSubmit={(values, actions) => {
              // alert(" submitting: "+ JSON.stringify( values, null, 2 ))
              emailLogin(values.email);
            }}
          />
        </Box>
        <Box {...row}>
          <p>Don't have an account?</p>
          <Button
            title="SIGN UP"
            style={{ width: '100%' }}
            onClick={() => onSignUpClick()}
          />
        </Box>
      </Container>
    </SignInWrapper>
  );
};

export default SignIn;
